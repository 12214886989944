import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "./SvgIcon";
import Container from "../../common/Container";
import LinkedInIcon from "./svg/linkedin.svg";
import TwitterIcon from "./svg/twitter.svg";
import MediumIcon from "./svg/medium.svg";

import {
  FooterSection,
  Para,
  FooterContainer,
  SocialLinkContainer,
} from "./styles";

const Footer = ({}) => {
  const socialLinks = [
    {
      href: "https://www.linkedin.com/company/brightfuturesai",
      Icon: LinkedInIcon,
    },
    { href: "https://twitter.com/BrightFuturesAI", Icon: TwitterIcon },
    // { href: "https://www.facebook.com", Icon: FacebookIcon },
    { href: "https://medium.com/@brightfuturesai", Icon: MediumIcon },
  ];

  return (
    <FooterSection>
      <Container>
        <Row justify="space-between">
          <Col lg={10} md={10} sm={12} xs={24}>
            <Para>Email: support@brightfuturesai.com</Para>
            <Para>254 Chapman Rd, STE 209</Para>
            <Para>Newark, DE</Para>
          </Col>
          <Col lg={14} md={14} sm={12} xs={24}>
            <SocialLinkContainer>
              {socialLinks.map(({ href, Icon }) => (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <SvgIcon src={Icon} width="25px" height="25px" />
                </a>
              ))}
            </SocialLinkContainer>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
